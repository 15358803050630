import React, {Component} from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import '../styles/Home.css'

class Welcome extends Component {
    constructor(props){
        super(props)
        this.state = {
            currentScreen:'Welcome',
            username:'',
            inAMeeting:false,
            emptyName:false
        }
    }
    handleNameChange = e => {
        this.setState({
          username:e.target.value
        })
      }
    
    join = () => {
        this.state.username ?
        this.props.handleJoin(
            this.state.username
        ):this.setState({
            emptyName: true
        })
    }
    create = () => {
        this.state.username ?
        this.props.handleCreate(
            this.state.username
        ):this.setState({
            emptyName: true
        })
    }
    render() {
        return (
        <div className="Welcome">
            <h1 className="App-name">UniQuest</h1>
            <TextField
              variant="outlined"
              margin="normal"
              required
            //   fullWidth
              name="user_name"
              label="Enter your name"
              type="text"
              id="user_name"
              value={this.state.username}
              onChange={this.handleNameChange}
            />
            <Typography component="h6" color="textSecondary" className="Warning">
            {this.state.emptyName && "Please enter your name!"}
            </Typography>
            <Box component="span" display="block" m={2}>
                <Button variant="contained" className="Welcome-button" color="primary" onClick={this.join}>
                    Join Meeting
            </Button>
            </Box>
            <Box component="span" display="block" m={2}>
                <Button variant="contained" className="Welcome-button" color="primary" onClick={this.create}>
                    Create Meeting
            </Button>
            </Box>
        </div>
    )
        }
}

export default Welcome;
