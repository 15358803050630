let newMessage = (jsonBody, meetingId) => {
  let messageData={}
  if (jsonBody.data.message) {
      let msg= jsonBody.data.message
      if (Number(msg.meetingId) === Number(meetingId)) {
          messageData = {
          messageId: msg.messageId,
          content: msg.content,
          time: msg.time,
          isHand: msg.isHand,
          isQuestion: msg.isQuestion,
          isResolved: msg.isResolved,
          meetingId: msg.meetingId,
          author: {
            name: msg.author.name,
            role: msg.author.role,
            number: msg.author.number
          }
        }
        
      }
  }
  return messageData
}

export default newMessage