import { createMuiTheme }  from '@material-ui/core/styles'
const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#69c9ff',
        main: '#0099ff',
        dark: '#006ccb'
      },
      secondary: {
        light: '#ff616f',
        main: '#ff1744',
        dark: '#c4001d'
      },
    },
    spacing: 4,
  });
  
export default theme

