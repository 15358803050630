import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid, Tooltip } from '@material-ui/core';
import "../styles/Chat.css"
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
const options = [
  'all inputs',
  'only questions',
  'only messages'
]

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEl, setSelectedEl] = React.useState('all inputs');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickElement = (event, element) => {
    setSelectedEl(element)
    props.handleFilter(element)
    setAnchorEl(null);
  }

  return (
    <Grid container className="Filter-wrap">
      <Grid item xs={6} className="Filter-text">
        <div className="Current-filter">Showing:</div>
        <div className="Current-filter">{(selectedEl)}</div>
      </Grid>
      <Grid item xs={6} className="Filter">
          <Tooltip title="Filter messages" arrow>
            <Button onClick={handleClick} className="Filter-button" color="primary" variant="contained">
              <FilterListRoundedIcon style={{ transform: "scale(1.5)" }} />
            </Button>
          </Tooltip>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {options.map((element, index) => (
              <MenuItem onClick={(event) => handleClickElement(event, element)}
                selected={element === selectedEl}
                key={element} >{element}</MenuItem>
            ))}
          </Menu>
      </Grid>
    </Grid>
  );
}