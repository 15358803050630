import React, { Component } from 'react'
import '../styles/Chat.css'
import { Button, Grid, TextField, Tooltip } from '@material-ui/core';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import {ReactComponent as SendQuestionIcon} from "../icons/SendQuestionIcon.svg"
// import Sockette from 'sockette';
// let ws = null
class TextInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            message: ''
        }
    }
    
    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.message!=="") {
            this.props.handleMessage(this.state.message, false, false)
        }
        this.setState({
            message:""
        })
    }
    handleQuestion = () => {
        if (this.state.message!=='') {
            this.props.handleMessage(this.state.message, false, true)            
        }
        this.setState({
            message:""
        })
    }
    handleMessageChange = e => {
        this.setState({
            message:e.target.value
          })
    }

    render() {
        return (
            <form noValidate onSubmit={this.handleSubmit} className="height">
                <Grid container className="height">
                    <Grid item xs={10}>
                        <TextField className="Text-field height" id="filled-basic" label="Write here ..." variant="filled"
                        value={this.state.message}
                        onChange={this.handleMessageChange} />
                    </Grid>
                    <Grid item xs={2} className="Send-buttons">
                        {this.props.role === "participant" &&
                            <Tooltip title="Send question" arrow>
                                <Button className="Icon" variant="contained" color="primary" onClick={this.handleQuestion}>
                                    <SendQuestionIcon alt="send Q" style={{ transform: "scale(1.5)" }} />
                                </Button>
                            </Tooltip>
                        }
                        <Tooltip title="Send message" arrow>
                            <Button className="Icon"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                <SendRoundedIcon style={{ transform: "scale(1.5)" }} />
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </form>
        );
    }    
}

export default TextInput;
