import React, { Component } from 'react';
import PanToolRoundedIcon from '@material-ui/icons/PanToolRounded';
import PanToolOutlinedIcon from '@material-ui/icons/PanToolOutlined';
import '../styles/Chat.css'
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip'

class RaiseHand extends Component {
    constructor(props) {
        super(props)
        this.state = {
            handRaised: false,
            lastMessage: ''
        }
    }

    handleHand = () => {
        this.setState({
            handRaised: !this.state.handRaised
        })
        if (this.state.handRaised) {
            this.props.resolve(this.state.lastMessage)
        } else {
            let lastmsg = this.props.handleMessage("", true, true)
            this.setState({
                lastMessage: lastmsg
            })
        }
        
    }
  render() {
    return (
        <Tooltip title="Raise hand" arrow>
            <Button className="Icon" variant="contained" color="primary" onClick={this.handleHand}>
                {this.state.handRaised ? <PanToolRoundedIcon style={{ transform: "scale(1.5)" }} /> :
                    <PanToolOutlinedIcon style={{ transform: "scale(1.5)" }} />}
            </Button>
        </Tooltip>
        
    )
  }
}

export default RaiseHand;