import React, { Component } from 'react'
import PanToolRoundedIcon from '@material-ui/icons/PanToolRounded';
import ChatRoundedIcon from '@material-ui/icons/ChatRounded'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import Grid from "@material-ui/core/Grid"
import Checkbox from "@material-ui/core/Checkbox"
import "../styles/Message.css"
import { Tooltip } from '@material-ui/core';

class Message extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isResolved: false, // todo deal with resolving messages
        }
    }

    handleChange = () => {
        this.setState({
            isResolved: !this.state.isResolved,
        })
        if (!this.state.isResolved) {
            this.props.resolve(this.props.id)    
        }

    }
    render() {
        return(
            <Grid container className={"Msg-wrapper " + (this.props.authorRole === "host" ? "Msg-ByHost" : 
            (this.props.isQuestion ? (this.props.isResolved ? "Msg-Resolved" : "Msg-unresolved") : "Msg-regular"))}
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                <Grid item xs={1} className="Msg-item">{this.props.time}</Grid>
                <Grid item xs={1} className="Msg-item Msg-type">{this.props.isHand ? <PanToolRoundedIcon /> : <ChatRoundedIcon />}</Grid>
                <Grid item xs={2} className="Msg-item Msg-author">{this.props.author}</Grid>
                <Grid item xs={1} className="Msg-item">{this.props.isQuestion && <HelpRoundedIcon />}</Grid>
                <Grid item xs={this.props.role === "host" ? 5 : 6} className="Msg-content">{this.props.text || ""}</Grid>
                <Grid item xs={1} className="Msg-item">
                    {this.props.isQuestion && <Tooltip title="# questions asked" arrow>
                        <div className="Msg-nb">{this.props.authorNb}</div>
                    </Tooltip>}
                </Grid>
                {this.props.role === "host" &&
                    <Grid item xs={1} className="Msg-item">{this.props.isQuestion &&
                        <Tooltip title="Resolve" arrow>
                            <Checkbox checked={this.props.isResolved} onChange={this.handleChange} />
                        </Tooltip>}
                    </Grid>
                }
            </Grid>
    )
    }
}

export default Message