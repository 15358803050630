import React, { Component } from 'react';
import Welcome from '../components/Welcome.js';
import Join from '../components/Join.js'
import Create from '../components/Create.js'
import '../styles/Home.css'

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inAMeeting: false,
            currentScreen: 'Welcome'
        }
      }

  onJoinMeeting = (username) => {
    this.setState({
      currentScreen: 'JoinMeeting',
      username: username
    })
    // todo add catch error
  }

  onCreateMeeting = (username) => {
    this.setState({
      currentScreen: 'CreateMeeting',
      username: username
    })
    this.props.handleCreate(username)
    // todo add catch error
  }

  handleBack = () => {
    this.setState({
      currentScreen: 'Welcome'
    })
  }

  render() {
    return (
          <header className="App-header">
            {this.state.currentScreen === 'Welcome' && <Welcome handleJoin={this.onJoinMeeting} handleCreate={this.onCreateMeeting}/>}
            {this.state.currentScreen === 'JoinMeeting' && <Join back={this.handleBack} invalid={this.props.invalid} handleJoin={this.props.handleJoin} username={this.state.username}/>}
            {this.state.currentScreen === 'CreateMeeting' && <Create back={this.handleBack} handleStart={this.props.handleStart} username={this.state.username} meetingId={this.props.id}/>}
          </header>
    )
  }
}

export default Home;