import React, { Component } from 'react';
import MessageList from '../containers/MessageList'
import '../styles/Chat.css'
import ChatHeader from '../components/ChatHeader';
import ChatInput from '../containers/ChatInput';
class Chat extends Component {
  constructor(props) {
      super(props)
      this.state = {
          inAMeeting: true,
          currentScreen: 'Chat',
          numberQuestions: 1,
          numInputs:0,
          filter:"all inputs"
      }
  }

  sendInput = (message, isHand, isQuestion) => {
    let today = new Date();
    let time=('0'  + today.getHours()).slice(-2)+':'+('0' + today.getMinutes()).slice(-2);
    let messageId = this.props.name+"_"+this.state.numInputs+"_"+time

    this.setState({
        numInputs: this.state.numInputs+1
      })

    if (this.state.numInputs<1000) {
      if (isQuestion) {
        this.setState({
         numberQuestions: this.state.numberQuestions+1
        })
      }

      let messageData = {
        messageId: messageId,
        content: message,
        time: time,
        isHand: isHand,
        isQuestion: isQuestion,
        isResolved: false,
        meetingId: this.props.id,
        author: {
          name: this.props.name,
          role: this.props.role,
          number: this.state.numberQuestions
        }
      }
      this.props.handleMessage(messageData)
    }
    return messageId
  }

  handleResolve = (messageId) => {
    this.props.handleResolve(this.props.id, messageId)
  }

  handleFilter = (filter) => {
    this.setState({
      filter: filter
    })
  }

  render() {
    return (
      <div className="Chat-wrapper">
        <ChatHeader role={this.props.role} name={this.props.name} id={this.props.id} handleEnd={this.props.handleEnd}/>
        <MessageList role={this.props.role} resolve={this.handleResolve} messages={this.props.messages} filter={this.state.filter}/>
        <ChatInput role={this.props.role} handleMessage={this.sendInput} resolve={this.handleResolve} handleFilter={this.handleFilter}/>
      </div>
    )
  }
}

export default Chat;