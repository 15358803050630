import React, { Component } from 'react';
import Message from '../components/Message';
import '../styles/Message.css'

class MessageList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inAMeeting: true,
            currentScreen: 'Chat'
        }
    }

  messages = [
    {time: "12:04",
    isHand: false,
    isQuestion: true,
    isResolved:true,
    authorRole: "host",
    content: "Can you explain the concept again?",
    authorNb: 2,
    author: {
      name: "Emily",
      number:2,
      role:"participant"}},
    {time: "10:40",
    isHand: true,
    isQuestion: true,
    authorNb: 1,
    text: "",
    author: {
      name:"Sam",
    number:1,
  role:"participant"}},
    {time: "11:03",
    isHand: false,
    isQuestion: false,
    authorNb: 1,
    authorRole: "participant",
    content: "I love this app!",
    author: {
      name: "Joe",
      number:1,
      role:"participant"}},
    {time: "07:53",
    isHand: false,
    isQuestion: true,
    authorNb: 3,
    authorRole: "participant",
    content: "Can you start over?",
    author: {
      name: "Anna",
      number:2,
      role:"participant"}}
  ]
  render() {
    let shownmessages=this.props.messages
    if (this.props.filter==="only questions") {
      shownmessages = this.props.messages.filter(element => element.isQuestion)
    } else if (this.props.filter==="only messages") {
      shownmessages = this.props.messages.filter(element => !element.isQuestion)
    } else {
      shownmessages=this.props.messages
    }
    return (
      <div className="Msg-list">
        {shownmessages.map((element, index) => (
          <Message resolve={this.props.resolve} id={element.messageId} key={element.messageId} role={this.props.role} time={element.time} authorNb={element.author.number} authorRole={element.author.role} isHand={element.isHand} isQuestion={element.isQuestion} isResolved={element.isResolved} text={element.content} author={element.author.name}/>
        ))}
        </div>
    )
  }
}

export default MessageList;