import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import "../styles/Home.css"

class Join extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentScreen: 'JoinMeeting',
      inAMeeting: false,
      meetingId: '',
      username: props.username,
      emptyId: false,
      // invalidId: props.invalid || false,
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    

    this.state.meetingId ? 
    this.props.handleJoin(this.state.username, this.state.meetingId)
    : this.setState({
      emptyId: true,
      invalidId: false
    })
  }

  handleNameChange = e => {
    this.setState({
      username:e.target.value
    })
  }
  handleIdChange = e => {
    this.setState({
      meetingId:e.target.value
    })
  }

  render() {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Button
        variant="contained"
        color="primary"
        className="back-button"
        onClick={this.props.back}>Back</Button>
        <div>
          <Typography component="h1" variant="h5">
            Join Meeting
        </Typography>
          <form noValidate onSubmit={this.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="meeting_id"
              label="Meeting ID"
              name="meeting_id"
              autoComplete=""
              autoFocus
              value={this.state.meetingId}
              onChange={this.handleIdChange}
            />
            <Typography component="h6" color="textSecondary" className="Warning">
            {/* {(this.state.emptyId) && "Please enter a meeting ID!"} */}
            {(this.state.emptyId || this.props.invalid) && "Please enter an existing meeting ID!"}
            </Typography>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Join
          </Button>
          </form>
        </div>
        <Box mt={8}>
        </Box>
      </Container>
    )
  }
}
export default Join