import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import "../styles/Chat.css"
import { Grid } from '@material-ui/core'

function ChatHeader(props) {
    return(
        <div>
            <Box className="Chat-header">
                <div className="Chat-info">
                    <div className="Chat-name">{props.name}</div>
                    <div className="Chat-id">Meeting ID: {props.id}</div>
                </div>
                <div className="Chat-AppName">UniQuest</div>
                <div className="Chat-end">
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={props.handleEnd}
                    >
                        {props.role === "host" ? "End Meeting" : "Leave"}
                    </Button>
                </div>
            </Box>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item xs={1} className="Header-item">Time</Grid>
                <Grid item xs={1} className="Header-item">Type</Grid>
                <Grid item xs={2} className="Header-item"></Grid>
                <Grid item xs={1} className="Header-item"></Grid>
                <Grid item xs={props.role === "host" ? 5 : 6}></Grid>
                <Grid item xs={1} className="Header-item"># of questions asked</Grid>
                {props.role === "host" &&
                    <Grid item xs={1} className="Header-item">Resolve</Grid>
                }
            </Grid>
        </div>
        
    )
}

export default ChatHeader