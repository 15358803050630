let importMessages = (jsonData) => {
    let messages=[]
    if (jsonData.existing_messages) {
        jsonData.existing_messages.forEach(element => {
            let msg = {
                messageId: element.messageId,
                content: element.content,
                time: element.time,
                isHand: element.isHand,
                isQuestion: element.isQuestion,
                isResolved: element.isResolved,
                meetingId: element.meetingId,
                author: {
                name: element.author.name,
                role: element.author.role,
                number: element.author.number
                }
            }
            messages.push(msg)
        });
    }
    return messages
}
export default importMessages