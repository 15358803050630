import './App.css';
import React, { Component } from 'react';
import Chat from './containers/Chat.js';
import Home from './containers/Home.js'
import theme from './theme'
import { ThemeProvider } from '@material-ui/styles';
import Sockette from 'sockette';
import newMessage from './socketMessages/newMessage'
import importMessages from './socketMessages/importMessages'
import resolve from './socketMessages/resolve'

class App extends Component {
  state = {
    username: null,
    meetingId: null,
    userrole: null,
    inAMeeting: false,
    currentScreen: 'Welcome',
    messages: [],
    invalidId: false
  }

  onMessageReceived = (e) => {
    console.log("Message Received:", e)
    if (e.data) {
      let jsonData=JSON.parse(e.data)
      console.log("Data: ", jsonData)
      if (jsonData.body) {
        let jsonMsgBody =JSON.parse(jsonData.body)
        if (jsonMsgBody.event) {
          let jsonMsgEventBody = JSON.parse(jsonMsgBody.event.body)
          if (jsonMsgEventBody.action){
            switch(jsonMsgEventBody.action) {
              case "sendInput":
                let msg=newMessage(jsonMsgEventBody,this.state.meetingId)
                if (msg) {
                  this.setState({
                    messages: [...this.state.messages, msg]
                  })
                }
                break;
              case "resolveInput":
                this.setState({
                  messages: resolve(jsonMsgEventBody.data.message.messageId, this.state.messages)
                })
                break;
              default:
                console.log("other event")
                break;
            }
          }
        }
      }
      else if (jsonData.event) {
        let jsonBody = JSON.parse(jsonData.event.body)
        if (jsonBody.action) {
          switch (jsonBody.action) {
            case "sendInput":
              let msg = newMessage(jsonBody, this.state.meetingId)
              if (msg) {
                this.setState({
                  messages: [...this.state.messages, msg]
                })
              }
              break;
            case "leaveRoom":
              this.setState({
                messages:[],
                inAMeeting:false,
                currentScreen:'Welcome',
                meetingId: null,
              })
              break;
            case "joinRoom":
              if (jsonData.meetingIdValid) {
                this.setState({
                    currentScreen: 'Chat',
                    inAMeeting: true,
                    invalidId: false,
                    // messages:[...this.state.messages,importMessages(jsonData)]
                    messages:this.state.messages.concat(importMessages(jsonData))
                })

              } else if (!jsonData.meetingIdValid){
                  this.setState({
                    invalidId: true
                })
              }
              break;
            case "createRoom":
              if (jsonData.meetingId) {
                this.setState({
                  meetingId: jsonData.meetingId
                })
              }
              break;
            case "resolveInput":
              this.setState({
                messages: resolve(jsonBody.data.message.messageId, this.state.messages)
              })
              break;
            default:
              console.log("Received other message")
              break;
          }
        }
      }
    }
  }
  
  ws = new Sockette(
    "wss://nnjhwqcx48.execute-api.ca-central-1.amazonaws.com/prod",
    {
      timeout: 5e3,
      maxAttempts: 1,
      onopen: e => console.log("connected:", e),
      onmessage: e => this.onMessageReceived(e),
      onreconnect: e => console.log("Reconnecting...", e),
      onmaximum: e => console.log("Stop Attempting!", e),
      onclose: e => console.log("Closed!", e),
      onerror: e => console.log("Error:", e)
    }
  )

  onStartMeeting = (username, id, role) => {
    this.setState({
      currentScreen: 'Chat',
      inAMeeting: true,
      username: username,
      meetingId: id,
      userrole: role
    })
  }

  onEndMeeting = () => {
    console.log("onEndMeeting")
    this.ws.json({
      action: "leaveRoom",
      data: {
        message:{
          meetingId: this.state.meetingId,
        }
      }
    })
  }

  onSendMessage = (message) => {
    console.log("onSendMessage: ", message)
    this.ws.json({
        action: "sendInput",
        data: {
            message:message,
        }
      });
  }
  onResolveInput = (id, msgId) => {
    console.log("onResolveInput: ", msgId)
    this.ws.json({
      action:"resolveInput",
      data: {
        message: {
          meetingId: id,
          messageId: msgId
        }
      }
    })
  }

  onCreateRoom = (name) => {
    console.log("onCreateRoom: ", this.state.username)
    this.ws.json({
      action:"createRoom",
      data: {
        message: {
          name:name
        }
      }
    })
  }

  onJoinRoom = (name,id) => {
    console.log("onJoinRoom: ", this.state.username)
    this.ws.json({
      action:"joinRoom",
      data: {
        message: {
          name:name,
          meetingId: id
        }
      }
    })
    this.setState({
      username: name,
      meetingId: id,
      userrole: 'participant'
    })
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          {this.state.inAMeeting ? <Chat messages={this.state.messages} handleMessage={this.onSendMessage} handleResolve={this.onResolveInput} handleEnd={this.onEndMeeting} name={this.state.username} role={this.state.userrole} id={this.state.meetingId}/> 
          : <Home invalid={this.state.invalidId} handleStart={this.onStartMeeting} handleCreate={this.onCreateRoom} id={this.state.meetingId} handleJoin={this.onJoinRoom}/>}
        </div>
      </ThemeProvider>
    )
  }
}

export default App;