import React from 'react'
import '../styles/Chat.css'
import TextInput from '../components/TextInput';
import Filter from '../components/Filter';
import RaiseHand from '../components/RaiseHand';
import { Grid } from '@material-ui/core';

function ChatInput(props) {
    return (
        <Grid container className="Chat-input">
            <Grid item xs={10}><TextInput role={props.role} handleMessage={props.handleMessage} /></Grid>
            <Grid item xs={2}>{props.role === "host" ? <Filter handleFilter={props.handleFilter} /> : <RaiseHand handleMessage={props.handleMessage} resolve={props.resolve}/>}</Grid>
        </Grid>
    );
}

export default ChatInput;
