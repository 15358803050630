import React, {Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Tooltip } from '@material-ui/core';
import "../styles/Home.css"

class Create extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentScreen: 'JoinMeeting',
      inAMeeting: false,
      username: props.username,
      copied: false,
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.handleStart(
      this.state.username,
      this.props.meetingId,
      'host'
    )
  }

  copyId = () => {
    let copyText = this.textArea;
    copyText.select()
    document.execCommand("copy")

    this.setState({
      copied: true,
    })
  }

  render() {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Button
        variant="contained"
        color="primary"
        className="back-button"
        onClick={this.props.back}>Back</Button>
        <div>
          <Typography component="h1" variant="h5">
            Join Meeting
        </Typography>
          <form noValidate onSubmit={this.handleSubmit}>
            <Box component="span" display="block" m={2}>
              
                <Typography component="h6" color="textPrimary" className="MeetingId">
                Meeting ID:
              </Typography>
              <div className="CopyId">
              <textarea
                  readOnly
                  ref={(textarea) => this.textArea = textarea}
                  value={this.props.meetingId || ''}
                  className="Id-textarea"
                />
                <Tooltip arrow title="Copy meeting ID">
                  <Button variant="contained" className="Copy-button" color="primary" onClick={this.copyId}>
                  <FileCopyIcon />
                </Button>
                </Tooltip>
                
              </div>
            <Typography component="h6" color="textSecondary" className="Warning">
            {this.state.copied && "Copied Meeting ID"}
            </Typography>
            </Box>
            <Box component="span" display="block" m={2}>
              <Button
                type="submit"
                className="Welcome-button"
                variant="contained"
                color="primary"
              >
                Join
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    )
  }
}
export default Create